// NO IDEA why I have to manually import the variables into this file, will solve later
@import 'variables';

%no-margin {
  margin: 0;
}

%no-padding {
  padding: 0;
}

%content-max-width {
  max-width: $screen-xl;
}

// scss-lint:disable ImportantRule

//visually-hidden prevents elements from being seen on screen, 
//but still allows them to be accessible to screen readers. 
//This scss extension should be used when hiding elements 
//that should still be accessible via a screen reader
%visually-hidden {
  // "!important" is used to prevent unintentional overrides.
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
}

.visually-hidden {
  @extend %visually-hidden;
}
// scss-lint:enable ImportantRule
